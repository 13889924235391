<template>
  <v-container fluid :key="tiertype">
    <v-row>
      <v-col cols="12" sm="6" md="12">
        <listitemspage
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qTotal="Qtotal"
          :PageResponse="'tiers_sit_page'"
          :AllResponse="'tiers_sit_all'"
          :TotalResponse="'totals_sit'"
          :title="
            tiertype == 1
              ? 'Situation des Fournisseurs'
              : 'Situation des Clients'
          "
          :headers="headers"
          :field="'SITUATION_GL'"
          :filename="tiertype == 1 ? 'Fournisseurs' : 'Clients'"
          :sheetname="tiertype == 1 ? 'Fournisseurs' : 'Clients'"
          :showedit="false"
          @rowselect="TierChange"
          @hrf="ShowList"
          :Add="false"
          :del="false"
          :TypeScope="tiertype"
          :byexercice="true"
          :byperiode="true"
          :list_options="false"
          :btn1="'Dettes'"
          :btn2="'Creances'"
          @click1="dettes_creances('LT')"
          @click2="dettes_creances('GT')"
          :key="tiertype"
          :header_print="dettes_headers"
          @periode_change="periode_change"
          @exercice_change="exercice_change"
          :periode="true"
        >
        </listitemspage>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <formdetail
      :key="kd"
      :showform="!isDetailClose"
      :doc="doc"
      @close="CloseDetail"
      @open="OpenDetail"
    >
    </formdetail>
    <formlist
      :title="title"
      :subtitle="subtitle"
      :list="list"
      :showform="!isListClose"
      :listheaders="listheaders"
      :key="fl"
      @close="closeListForm"
      :width="width"
      :Total="true"
      :master="true"
      :kid="kid"
      @open="OpenDetail"
      :orientation="orientation"
    >
    </formlist>
  </v-container>
</template>

<script>
const listitemspage = () => import("../components/ListItemsPages.vue");
const formdetail = () => import("../components/FormDetail.vue");
const formlist = () => import("../components/FormList.vue");

import SIT_TIERS from "../graphql/Tier/SIT_TIERS.gql";
import SIT_TIERS_TOTALS from "../graphql/Tier/SIT_TIERS_TOTALS.gql";
import SIT_TIERS_ALL from "../graphql/Tier/SIT_TIERS_ALL.gql";
import SIT_TIER_DETAIL from "../graphql/Tier/SIT_TIER_DETAIL.gql";
import ALLCMDS from "../graphql/Commande/ALLCMDS.gql";
import ALLMVM from "../graphql/Mouvment/ALLMVM.gql";
import ALLFACT from "../graphql/Facture/ALLFACT.gql";
import ALLPAYE from "../graphql/Paiement/ALLPAYE.gql";

import { myBL } from "print/data.js";

export default {
  components: {
    listitemspage,
    formdetail,
    formlist,
  },
  props: {
    tiertype: Number,
  },
  data: () => ({
    klist: 0,
    orientation: "p",
    width: "1000px",
    title: "",
    isListClose: true,
    isDetailClose: true,
    list: [],
    kid: "id",
    listheaders: [],
    sitheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
        sortable: false,
      },
      {
        text: "Date operation",
        value: "date_opr",
        slot: "date",
        selected: true,
        sortable: false,
      },
      {
        text: "Reglé/Lettré",
        value: "ttc_paye",
        align: "end",
        slot: "cur33",
        selected: false,
        sortable: false,
      },
      {
        text: "Reste",
        value: "reste",
        align: "end",
        slot: "cur33",
        selected: false,
        sortable: false,
      },
      {
        text: "Reference",
        value: "nodoc",
        selected: true,
        sortable: false,
      },
      {
        text: "Operation",
        value: "opr",
        slot: "checked",
        column: "cloture",
        valeur: true,
        selected: true,
        sortable: false,
      },

      {
        text: "Mode Paiement",
        value: "modepaiement",
        selected: true,
        sortable: false,
      },
      {
        text: "Debit",
        value: "debit",
        align: "end",
        slot: "cur33",
        selected: true,
        total: "debit",
        sortable: false,
      },
      {
        text: "Credit",
        value: "credit",
        align: "end",
        slot: "cur33",
        total: "credit",
        selected: true,
        sortable: false,
      },
      {
        text: "Situation",
        value: "situation",
        align: "end",
        slot: "cur22",
        selected: true,
        sortable: false,
      },
    ],
    cmdheaders: [
      {
        text: "Code",
        value: "nocmd",
        slot: "href",
        enum: "NOCMD",
        totlib: true,
        selected: true,
      },
      {
        text: "Ref.",
        value: "cmd_ref",
        enum: "CMD_REF",
        selected: true,
      },
      {
        text: "Date commande",
        value: "date_cmd",
        slot: "date",
        enum: "DATE_CMD",
        selected: true,
      },
      {
        text: "H.T",
        value: "mont_ht",
        align: "end",
        slot: "cur",
        selected: true,
        total: "mont_ht",
      },
      {
        text: "T.T.C.",
        value: "mont_ttc",
        align: "end",
        slot: "cur",
        selected: true,
        total: "mont_ttc",
      },
      {
        text: "Statut.",
        value: "statut_id",
        selected: false,
        hiden: true,
      },
      {
        text: "Avant le",
        value: "date_limite",
        slot: "date",
        selected: true,
        sortable: false,
      },
      {
        text: "Statut",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "text-center",
        expire: true,
        selected: true,
      },
    ],
    mvmheaders: [
      {
        text: "Code",
        value: "nobon",
        slot: "href",
        totlib: true,
        selected: true,
      },
      {
        text: "N° BL",
        value: "nomvm",
        selected: true,
      },
      {
        text: "Ref.",
        value: "mvm_ref",
        selected: true,
      },
      {
        text: "Date mouvement",
        value: "mvm_date",
        slot: "date",
        selected: true,
      },
      {
        text: "Commande",
        value: "nocmd",
        selected: true,
        hiden: true,
      },
      {
        text: "H.T",
        value: "mont_ht",
        align: "end",
        slot: "cur",
        selected: true,
        width: "150",
        total: "mont_ht",
      },
      {
        text: "T.T.C.",
        value: "mont_ttc",
        align: "end",
        slot: "cur",
        selected: true,
        width: "150",
        total: "mont_ttc",
      },
      {
        text: "Ret.",
        value: "mont_ret",
        align: "end",
        slot: "cur",
        selected: true,
        width: "150",
        total: "mont_ret",
      },
      {
        text: "Réglé",
        value: "ttc_paye",
        align: "end",
        slot: "cur",
        hiden: true,
        total: "ttc_paye",
        width: "150",
        selected: true,
      },
      {
        text: "No Facture",
        value: "nofact",
        align: "text-center",
        expire: true,
        selected: true,
      },
      {
        text: "Statut",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "text-center",
        expire: true,
        selected: true,
      },
    ],
    factheaders: [
      {
        text: "Code",
        value: "nofact",
        slot: "href",
        enum: "NOFACT",
        totlib: true,
        selected: true,
      },
      {
        text: "Date facture",
        value: "fact_date",
        slot: "date",
        enum: "FACT_DATE",
        selected: true,
      },
      {
        text: "Ref.",
        value: "fact_ref",
        enum: "FACT_REF",
        selected: true,
      },
      {
        text: "H.T",
        value: "mont_ht",
        align: "end",
        slot: "cur",
        selected: true,
        total: "mont_ht",
      },
      {
        text: "T.T.C.",
        value: "mont_ttc",
        enum: "MONT_TTC",
        align: "end",
        slot: "cur",
        total: "mont_ttc",
        selected: true,
      },
      {
        text: "Réglé",
        value: "ttc_paye",
        enum: "TTC_PAYE",
        align: "end",
        slot: "cur",
        total: "ttc_paye",
        selected: true,
      },
      {
        text: "Statut",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "text-center",
        expire: true,
        selected: true,
      },
    ],
    payeheaders: [
      {
        text: "Code",
        value: "nopaye",
        slot: "href",
        enum: "NOPAYE",
        selected: true,
        totlib: true,
      },
      {
        text: "Opr",
        value: "operation",
        selected: true,
      },
      {
        text: "Mode Paiement",
        value: "type_paye",
        enum: "TYPE_PAYE",
        selected: true,
      },
      {
        text: "Ref.",
        value: "paye_ref",
        enum: "PAYE_REF",
        selected: true,
      },
      {
        text: "No Piece",
        value: "nocheque",
        enum: "NOCHEQUE",
        selected: true,
      },
      {
        text: "Date Piece",
        value: "date_cheque",
        slot: "date",
        enum: "DATE_CHEQUE",
        selected: true,
      },
      {
        text: "Date Operation",
        value: "paye_date",
        slot: "date",
        enum: "PAYE_DATE",
        selected: true,
      },

      {
        text: "Montant ",
        value: "montant",
        enum: "MONTANT",
        slot: "cur22",
        selected: true,
        align: "end",
        total: "montant",
      },
      {
        text: "Lettré ",
        value: "ttc_det",
        enum: "TTC_DET",
        slot: "cur22",
        selected: true,
        align: "end",
        total: "ttc_det",
      },
      {
        text: "Reste ",
        value: "reste",
        enum: "RESTE",
        slot: "cur22",
        selected: true,
        align: "end",
        total: "reste",
      },
      {
        text: "Statut",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "text-center",
        selected: true,
      },
    ],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",

    tabs2: null,
    search: null,
    headers: [
      {
        text: "Code",
        value: "code_client",
        enum: "CODE_CLIENT",
        totlib: true,
        selected: true,
      },
      {
        text: "Nom",
        align: "start",
        value: "nom",
        enum: "NOM",
        selected: true,
      },
      {
        text: "Statut",
        value: "statuts_name",
        enum: "STATUTS_NAME",
        selected: false,
        slot: "chip",
        color: "color",
      },
      {
        text: "Type",
        value: "tier_type_name",
        width: "100",
        enum: "TIER_TYPE_NAME",
        selected: false,
      },
      {
        text: "Auxiliaire",
        value: "account_number",
        width: "100",
        enum: "ACCOUNT_NUMBER",
        selected: false,
      },

      {
        text: "Situation Init.",
        value: "situation_ini",
        enum: "SITUATION_INI",
        align: "end",
        slot: "href2",
        result: "situation_ini",
        selected: false,
      },
      {
        text: "Sit. Enl. Init.",
        value: "situation_bl_ini",
        enum: "SITUATION_BL_INI",
        align: "end",
        slot: "href2",
        result: "situation_bl_ini",
        hiden: true,
        selected: false,
      },
      {
        text: "Commande",
        value: "cmd_ttc",
        enum: "CMD_TTC",
        align: "end",
        slot: "href2",
        result: "cmd_ttc",
        selected: false,
      },
      {
        text: "Expédition",
        value: "val_tot_liv",
        enum: "VAL_TOT_LIV",
        align: "end",
        slot: "href2",
        result: "val_tot_liv",
        hiden: true,
        selected: true,
      },
      {
        text: "Retours",
        value: "val_tot_ret",
        enum: "VAL_TOT_RET",
        align: "end",
        slot: "href2",
        result: "val_tot_ret",
        hiden: true,
        selected: true,
      },
      {
        text: "Factures",
        value: "ttc_fact",
        enum: "TTC_FACT",
        align: "end",
        slot: "href2",
        result: "ttc_fact",
        selected: true,
      },
      {
        text: "Avoir",
        value: "ttc_fact_av",
        enum: "TTC_FACT_AV",
        align: "end",
        slot: "href2",
        result: "ttc_fact_av",
        selected: true,
      },
      {
        text: "Encais. Enl",
        value: "paye_enl",
        enum: "PAYE_ENL",
        align: "end",
        result: "paye_enl",
        slot: "href2",
        hiden: true,
        selected: true,
      },
      {
        text: "Encaissements",
        value: "paye_fact",
        enum: "PAYE_FACT",
        align: "end",
        result: "paye_fact",
        slot: "href2",
        selected: true,
      },

      {
        text: "Situation",
        value: "situation_fact",
        enum: "SITUATION_FACT",
        align: "end",
        result: "situation_fact",
        slot: "href3",
        selected: true,
      },
      {
        text: "Sit.BL",
        value: "situation_enl",
        enum: "SITUATION_ENL",
        align: "end",
        result: "situation_enl",
        slot: "href3",
        hiden: true,
        selected: true,
      },
      {
        text: "Sit. GL",
        value: "situation_gl",
        enum: "SITUATION_GL",
        align: "end",
        result: "situation_gl",
        slot: "href3",
        hiden: true,
        selected: true,
      },
    ],
    dettes_headers: [
      {
        text: "Code",
        value: "code_client",
        totlib: true,
        selected: true,
      },
      {
        text: "Nom",
        align: "start",
        value: "nom",
        selected: true,
      },
      {
        text: "Sit Ini.",
        value: "situation_ini",
        align: "end",
        slot: "cur",
        total: "situation_ini",
        selected: true,
      },
      {
        text: "Debit",
        value: "tot_fact",
        align: "end",
        slot: "cur",
        total: "tot_fact",
        selected: true,
      },

      {
        text: "Credit",
        value: "paye_fact",
        align: "end",
        total: "paye_fact",
        slot: "cur",
        selected: true,
      },

      {
        text: "Situation",
        value: "situation_fact",
        align: "end",
        slot: "cur",
        total: "situation_fact",
        selected: true,
      },
    ],

    fk: 0,
    kd: 100,
    fl: 200,

    doc: {},
    tier: {},
    docs_list: [],
    paye_bl: false,
    dd: "",
    df: "",
    subtitle: null,
  }),

  computed: {
    Qselect() {
      return SIT_TIERS;
    },
    Qselectall() {
      return SIT_TIERS_ALL;
    },
    Qtotal() {
      return SIT_TIERS_TOTALS;
    },
    tier_name() {
      return this.tiertype == 1 ? "Fournisseur" : "Client";
    },
  },
  watch: {},
  created() {},

  mounted() {
    this.$store.dispatch("Changed", true);
    this.exercice = this.$store.state.exercice;
    this.dd = this.exercice.toString() + "-01-01";
    this.df = this.$store.state.today;
    //si noir && autorisation ou blanc
    if (
      (myBL == "n" &&
        (this.$store.state.auth.includes("05021") ||
          this.$store.state.isadmin)) ||
      myBL == "b"
    ) {
      this.headers[6].hiden = myBL == "b";
      this.headers[8].hiden = false;
      this.headers[9].hiden = false;
      this.headers[12].hiden = myBL == "b";

      this.headers[15].hiden = myBL == "b";
      this.headers[16].hiden = myBL == "b";

      this.dettes_headers[3].value = "tot_mvm";
      this.dettes_headers[3].total = "tot_mvm";
      this.dettes_headers[4].value = "tot_paye";
      this.dettes_headers[4].total = "tot_paye";
      this.dettes_headers[5].value = "situation_gl";
      this.dettes_headers[5].total = "situation_gl";

      this.mvmheaders[8].hiden = myBL == "b";
      this.paye_bl = true;
    }
    this.headers[0].value =
      this.tiertype == 1 ? "code_fournisseur" : "code_client";
    this.headers[0].enum =
      this.tiertype == 1 ? "CODE_FOURNISSEUR" : "CODE_CLIENT";
    this.headers[8].text = this.tiertype == 1 ? "Receptions" : "Expédition";
    this.headers[13].text = this.tiertype == 1 ? "Paiements" : "Encaissements";
  },

  methods: {
    sortBy(prop) {
      return function (a, b) {
        return a[prop] > b[prop] ? -1 : 1;
      };
    },
    periode_change(dd, ff) {
      this.dd = dd;
      this.df = ff;
    },
    exercice_change(exercice) {
      this.exercice = exercice;
      this.dd = this.exercice.toString() + "-01-01";
      this.df = this.exercice.toString() + "-12-31";
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async dettes_creances(opr) {
      if (this.tiertype == 1) {
        this.dettes_headers[0].value = "code_fournisseur";
        this.dettes_headers[3].text = "Credit";
        this.dettes_headers[4].text = "Debit";
      }
      this.listheaders = this.dettes_headers;
      this.title = opr == "LT" ? "Liste des Dettes " : "Liste des Creances ";
      let v;
      if (this.paye_bl)
        v = {
          where: { column: "SITUATION_GL", operator: opr, value: 0 },
          orderBy: [{ column: "SITUATION_GL", order: "DESC" }],
          TypeScope: this.tiertype,
          date_debut: this.dd,
          date_fin: this.df,
        };
      else
        v = {
          where: { column: "SITUATION_FACT", operator: opr, value: 0 },
          orderBy: [{ column: "SITUATION_FACT", order: "DESC" }],
          TypeScope: this.tiertype,
          date_debut: this.dd,
          date_fin: this.df,
        };
      let r = await this.requette(SIT_TIERS_ALL, v);
      this.width = "1300px";
      if (r) this.list = r.tiers_sit_all;
      this.isListClose = false;
      this.fl++;
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },

    ShowList(item) {
      let w;
      let query;
      let result;
      let s = "situation_fact";
      this.width = "1400px";
      if (this.paye_bl) {
        s = "situation_gl";
      }
      this.kid = "id";

      switch (item.col) {
        case "situation_gl":
          this.title =
            "Situation du " +
            this.tier_name +
            " - " +
            item.nom +
            " : " +
            this.numberWithSpace(item.situation_gl.toFixed(2));

          this.listheaders = this.sitheaders;
          this.kid = "docid";
          w = {
            tier_id: item.id,
            tiertype: this.tiertype,
            facture: [0, 1],
            date_debut: this.dd,
            date_fin: this.df,
          };
          query = SIT_TIER_DETAIL;
          result = "sit_tier_detail";

          this.width = "1300px";
          this.orientation = "l";
          break;
        case "situation_fact":
          this.title =
            "Situation du " +
            this.tier_name +
            " - " +
            item.nom +
            " : " +
            this.numberWithSpace(item.situation_fact.toFixed(2));

          this.listheaders = this.sitheaders;
          this.kid = "docid";
          w = {
            tier_id: item.id,
            tiertype: this.tiertype,
            facture: [1],
            date_debut: this.dd,
            date_fin: this.df,
          };
          query = SIT_TIER_DETAIL;
          result = "sit_tier_detail";

          this.width = "1300px";
          this.orientation = "l";
          break;
        case "situation_enl":
          this.title =
            "Situation du " +
            this.tier_name +
            " - " +
            item.nom +
            " : " +
            this.numberWithSpace(item.situation_enl.toFixed(2));

          this.listheaders = this.sitheaders;
          this.kid = "docid";
          w = {
            tier_id: item.id,
            tiertype: this.tiertype,
            facture: [0],
            date_debut: this.dd,
            date_fin: this.df,
          };
          query = SIT_TIER_DETAIL;
          result = "sit_tier_detail";

          this.width = "1300px";
          this.orientation = "l";
          break;
        case "situation_bl_ini":
          this.title =
            "Situation du " +
            this.tier_name +
            " - " +
            item.nom +
            " : " +
            this.numberWithSpace(item.situation_bl_ini.toFixed(2));

          this.listheaders = this.sitheaders;
          this.kid = "docid";
          w = {
            tier_id: item.id,
            tiertype: this.tiertype,
            facture: [3],
            date_debut: this.dd,
            date_fin: this.df,
          };
          query = SIT_TIER_DETAIL;
          result = "sit_tier_detail";

          this.width = "1300px";
          this.orientation = "l";
          break;

        case "situation_ini":
          this.title =
            "Situation du " +
            this.tier_name +
            " - " +
            item.nom +
            " : " +
            this.numberWithSpace(item.situation_ini.toFixed(2));

          this.listheaders = this.sitheaders;
          this.kid = "docid";
          w = {
            tier_id: item.id,
            tiertype: this.tiertype,
            facture: [4],
            date_debut: this.dd,
            date_fin: this.df,
          };
          query = SIT_TIER_DETAIL;
          result = "sit_tier_detail";

          this.width = "1300px";
          this.orientation = "l";
          break;

        case "cmd_ttc":
          this.listheaders = this.cmdheaders;
          this.title = "Commandes " + this.tier_name + " : " + item.nom;
          query = ALLCMDS;
          this.width = "1400px";
          result = "allcmds";
          w = {
            where: {
              AND: [
                { column: "STATUT_ID", operator: "GTE", value: "7" },
                { column: "TIER_ID", value: item.id },
                { column: "CMD_TYPE", value: this.tiertype == 1 ? 2 : 1 },
                { column: "DATE_CMD", operator: "GTE", value: this.dd },
                { column: "DATE_CMD", operator: "LTE", value: this.df },
              ],
            },
          };

          break;
        case "val_tot_liv":
          w = {
            where: {
              AND: [
                { column: "STATUT_ID", operator: "GTE", value: "11" },
                { column: "TIER_ID", value: item.id },
                {
                  column: "MVM_TYPE",
                  operator: "IN",
                  value: this.tiertype == 1 ? ["1", "17"] : ["3", "23"],
                },
                { column: "MVM_DATE", operator: "GTE", value: this.dd },
                { column: "MVM_DATE", operator: "LTE", value: this.df },
              ],
            },
          };

          this.listheaders = this.mvmheaders;
          if (this.tiertype == 1)
            this.title = "Receptions Fournisseur : " + item.nom;
          else this.title = "Expédition Client : " + item.nom;
          query = ALLMVM;
          result = "allmvm";
          this.width = "1300px";
          this.orientation = "l";

          break;
        case "val_tot_ret":
          w = {
            where: {
              AND: [
                { column: "STATUT_ID", operator: "GTE", value: "11" },
                { column: "TIER_ID", value: item.id },
                { column: "MVM_TYPE", value: this.tiertype == 1 ? "2" : "4" },
                { column: "MVM_DATE", operator: "GTE", value: this.dd },
                { column: "MVM_DATE", operator: "LTE", value: this.df },
              ],
            },
          };

          this.listheaders = this.mvmheaders;
          if (this.tiertype == 1)
            this.title = "Retours Fournisseur : " + item.nom;
          else this.title = "Retours Client : " + item.nom;

          query = ALLMVM;
          result = "allmvm";
          this.width = "1300px";
          this.orientation = "l";

          break;
        case "ttc_fact":
          this.listheaders = this.factheaders;
          this.title = "Factures " + this.tier_name + " : " + item.nom;
          query = ALLFACT;
          result = "allfact";
          w = {
            where: {
              AND: [
                { column: "STATUT_ID", operator: "GTE", value: "14" },
                { column: "TIER_ID", value: item.id },
                { column: "FACT_TYPE", value: this.tiertype == 1 ? 1 : 3 },
                { column: "FACT_DATE", operator: "GTE", value: this.dd },
                { column: "FACT_DATE", operator: "LTE", value: this.df },
              ],
            },
          };
          this.width = "1300px";
          this.orientation = "l";

          break;
        case "ttc_fact_av":
          this.listheaders = this.factheaders;
          this.title = "Factures Avoir " + this.tier_name + " : " + item.nom;
          query = ALLFACT;
          result = "allfact";
          w = {
            where: {
              AND: [
                { column: "STATUT_ID", operator: "GTE", value: "14" },
                { column: "TIER_ID", value: item.id },
                { column: "FACT_TYPE", value: this.tiertype == 1 ? 2 : 4 },
                { column: "FACT_DATE", operator: "GTE", value: this.dd },
                { column: "FACT_DATE", operator: "LTE", value: this.df },
              ],
            },
          };
          this.width = "1300px";
          this.orientation = "l";

          break;
        case "paye_enl":
          this.listheaders = this.payeheaders;
          this.title =
            "Reglement Enlevements " + this.tier_name + " : " + item.nom;
          query = ALLPAYE;
          result = "allpaye";
          w = {
            where: {
              AND: [
                { column: "STATUT_ID", operator: "GTE", value: "17" },
                { column: "TIER_ID", value: item.id },
                { column: "FACTURE", value: "0" },
                { column: "PAYE_DATE", operator: "GTE", value: this.dd },
                { column: "PAYE_DATE", operator: "LTE", value: this.df },
              ],
            },
          };
          this.payeheaders[4].hiden = true;
          this.payeheaders[5].hiden = true;
          this.width = "1300px";
          this.orientation = "l";

          break;
        case "paye_fact":
          this.listheaders = this.payeheaders;
          if (this.tiertype == 1)
            this.title = "Paiements/Encaissements Fournisseur : " + item.nom;
          else this.title = "Encaissements/Paiements Client : " + item.nom;
          this.width = "1300px";
          query = ALLPAYE;
          result = "allpaye";
          w = {
            where: {
              AND: [
                { column: "STATUT_ID", operator: "GTE", value: "17" },
                { column: "TIER_ID", value: item.id },
                { column: "FACTURE", value: "1" },
                { column: "PAYE_DATE", operator: "GTE", value: this.dd },
                { column: "PAYE_DATE", operator: "LTE", value: this.df },
              ],
            },
          };
          this.payeheaders[4].hiden = false;
          this.payeheaders[5].hiden = false;
          this.width = "1300px";
          this.orientation = "l";
          break;

        default:
          break;
      }
      this.$apollo
        .query({
          query: query,
          variables: w,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.list = data.data[result];
          if (item.col == s) {
            let l = this.list.filter((elm) => elm.opr == "Encaissement");
            if (l.length > 0) {
              l.sort(this.sortBy("date_opr"));
              this.subtitle =
                " Dernier encaissement le " +
                this.datefr(l[0].date_opr) +
                " Montant : " +
                this.numberWithSpace(l[0].credit.toFixed(2));
            }
          }
          this.isListClose = false;
          this.fl++;
        })
        .catch((error) => {
          this.snackbar_color = "error";
          this.snackbar_text = error.message;
          this.snackbar = true;
        });
    },
    print_sit() {},
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    closeListForm() {
      this.isListClose = true;
    },
    OpenDetail(item) {
      item.document_id = item.id;
      this.doc = item;
      this.kd++;
      this.isDetailClose = false;
    },
    CloseDetail() {
      this.isDetailClose = true;
    },

    TierChange(item) {
      this.tier = item;
    },
  },
};
</script>
